import { IonButton, IonContent, IonImg, IonInput, IonItem, IonItemDivider, IonNote, IonPage, IonText } from "@ionic/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import { Redirect, useHistory, useLocation } from "react-router";
import { config } from "../config";
import { useAuthState } from "react-firebase-hooks/auth";

import { isPlatform } from '@ionic/react';

import "./LoginPage.css"
import JudgeInterface from "../components/JudgeInterface";

const LoginPage: React.FC = () => {
    let history = useHistory()
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [verified, setVerified] = useState<boolean>(false)
    // const [verifiedUser,setVerifiedUser] = useState(firebase.auth().currentUser)
    const [verifiedUser,] = useAuthState(firebase.auth())
    const location = useLocation()
    const [conferenceCode, setConferenceCode] = useState<string>("")
    const [anonymous, setAnonymous] = useState<boolean>(false)

    useEffect(() => {
        const user = new URLSearchParams(location.search).get("user")
        const eventCode = new URLSearchParams(location.search).get("event_code")
        const code = new URLSearchParams(location.search).get("code")
        if (user != null && eventCode != null) {
            authenticateAccount(user, eventCode)
        }

        if (code != null) {

            checkConferenceCode(code)

        }
    })

    async function verifyAccount(email: string) {
        await Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-judges?email=${email}`).then((response) => {
            const data: any = response.data;

            if (data.length >= 1) {
                if (email == data[0].email) {
                    setVerified(true)
                    alert("Email Verified")

                }

                else {
                    alert("Email does not exist!")
                }

            }

            else {
                alert("Email does not exist!")
            }

        }).catch((err) => {
            alert("An error occured verifying email. Contact System Administrator.")
            console.log(err);
        })
    }

    function authenticateAccount(email: string, password: string) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            return firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
                history.push("/home")

            }).catch((err) => {
                console.error(err);
                alert(err.message)
            })
        }).catch((err) => {
            console.error(err)
            alert(err)
        })

    }

    function createAndFetchAnonymousJudge() {

        let anonJudge: JudgeInterface = {
            email: `${firebase.auth().currentUser?.uid!}@conferenceio.com`,
            event_code: "",
            first_name: "anonymous",
            last_name: "anonymous",
            flight: "",
            university: "",
            device_token: "",
            is_anonymous: true,
        }

        Axios.post(`${process.env.REACT_APP_STRAPI_SERVER}/research-judges`, anonJudge).then((res) => {

        }).catch((err) => {
            console.log(err);
            alert('Error getting judge info.')
        })
    }

    function checkConferenceCode(code: string) {

        if (code === "vbs2024") {
            firebase.auth().signInAnonymously().then(() => {
                createAndFetchAnonymousJudge()
                history.push("/feedback/home")
            })
        } else {
            alert("Invalid conference code!")
        }


    }


    if (verifiedUser) {


        if (firebase.auth().currentUser?.isAnonymous!) {
            return (
                <Redirect to="/feedback/home"></Redirect>
            )
        } else {
            return (
                <Redirect to="/home"></Redirect>
            )
        }


    }

    else {
        return (
            isPlatform('tablet') || isPlatform('desktop') ?
                (<IonPage>
                    <IonContent>
                        <IonItem lines="none" class="input-box">
                            <IonImg src="https://appwrite-dev.nicholasdwest.com/v1/storage/buckets/669179b878d7650792f1/files/669179cf7554f9f84946/view?project=65e5ebea53ab9b72ac43&mode=admin"></IonImg>
                        </IonItem>
                        <IonItem class="ion-padding-start ion-padding-end ion-margin-top input-box">
                            <IonInput class="login-email" type="email" placeholder="Enter Email" onIonChange={(e) => { setEmail(e.detail.value!) }}></IonInput>
                            <IonButton hidden={verified} onClick={() => { verifyAccount(email) }}>Submit</IonButton>
                        </IonItem>
                        <IonItem class="ion-padding-start ion-padding-end input-box" >
                            <IonInput class="login-password" type="password" placeholder="Password" onIonChange={(e) => { setPassword(e.detail.value!) }}></IonInput>
                            <IonButton onClick={() => { authenticateAccount(email, password) }}>Login</IonButton>
                        </IonItem>

                        {/* <IonItemDivider></IonItemDivider> */}

                        <div style={{ marginLeft: '45%', marginTop: '2%' }}>
                            <IonItem lines="none" class="ion-text-center">
                                <IonNote class="ion-text-center">Or</IonNote>
                            </IonItem>
                        </div>


                        <div className="ion-margin-left" style={{ width: '40%', marginLeft: '30%' }}>
                            <IonItem>

                                <IonInput placeholder="Enter Conference Code" onIonChange={(e) => { setConferenceCode(e.detail.value!) }}></IonInput>


                                <IonButton expand="full" slot="end" onClick={() => { checkConferenceCode(conferenceCode) }}>Join</IonButton>
                            </IonItem>

                            <IonItem lines="none">
                                <IonNote>Conference Code won't be valid until Monday March 6th 2023</IonNote>
                            </IonItem>
                        </div>

                    </IonContent>
                </IonPage>) :

                (<IonPage>
                    <IonContent>
                        <div style={{ width: '80%', marginLeft: '8%' }}>
                            <IonItem lines="none">
                                <IonImg src="https://appwrite-dev.nicholasdwest.com/v1/storage/buckets/669179b878d7650792f1/files/669179cf7554f9f84946/view?project=65e5ebea53ab9b72ac43&mode=admin"></IonImg>
                            </IonItem>
                            <IonItem class="ion-padding-end ion-margin-top">
                                <IonInput class="login-email" type="email" placeholder="Enter Email" onIonChange={(e) => { setEmail(e.detail.value!) }}></IonInput>
                            </IonItem>
                            <IonItem >
                                <IonInput class="login-password" type="password" placeholder="Password" onIonChange={(e) => { setPassword(e.detail.value!) }}></IonInput>
                                <IonButton onClick={() => { authenticateAccount(email, password) }}>Login</IonButton>
                            </IonItem>
                        </div>


                        <div style={{ marginLeft: '40%', marginTop: '5%' }}>
                            <IonItem lines="none" class="ion-text-center">
                                <IonNote class="ion-text-center">Or</IonNote>
                            </IonItem>
                        </div>


                        <div className="ion-margin-left" style={{ width: '80%', marginLeft: '10%' }}>
                            <IonItem>

                                <IonInput onIonChange={(e) => { setConferenceCode(e.detail.value!) }} placeholder="Enter Conference Code"></IonInput>


                                <IonButton expand="full" slot="end" onClick={() => { checkConferenceCode(conferenceCode) }}>Join</IonButton>
                            </IonItem>

                            <IonItem lines="none">
                                <IonNote>Conference Code won't be valid until Monday March 6th 2023</IonNote>
                            </IonItem>
                        </div>



                    </IonContent>
                </IonPage>)

        )
    }

}

export default LoginPage;
